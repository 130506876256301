import dashboard from "./menu/dashboard";
import administrator from "./menu/administrator";
import rolePermission from "./menu/rolePermission";
import company from "./menu/company";
import senior from "./menu/senior";
import master from "./menu/master";
import agent from "./menu/agent";
import player from "./menu/player";
import transaction from "./menu/transaction";
import betSetting from "./menu/betSetting";
import balanceReport from "./menu/balanceReport";
import resultReport from "./menu/resultReport";

export default [
  ...dashboard,
  ...administrator,
  ...rolePermission,
  ...betSetting,
  ...company,
  ...senior,
  ...master,
  ...agent,
  ...player,
  ...transaction,
  ...balanceReport,
  ...resultReport,
]
